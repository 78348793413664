<template>

      <Navbar/>

      <div class="container-fluid">
        <div class="row">
          <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar ">
            <div class="pt-1">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <router-link class="nav-link " active-class="active" to="/showcase">
                    <i class="bi bi-emoji-dizzy"></i> Showcase
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" active-class="active" to="/monkey-society">
                    <i class="bi bi-emoji-heart-eyes"></i> Monkey Society
                  </router-link>
                </li>
                <li class="nav-item">
                  <br>
                  <a  class="nav-link" href="mailto:dev@gmail.com" target="_blank">
                  <i class="bi bi-envelope"></i> hello@lyhco.com
                  </a>
                </li>
                <li class="nav-item">
                  <br>
                </li>
                
              </ul>
            </div>
          </nav>

          <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 ">
              <router-view/>
          </main> 

        </div>
      </div>


</template>

<script>
import Navbar from '@/views/_layout/Navbar.vue';
import { onMounted, onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex';

export default {  
  setup() {
    const store = useStore();

    return {
      Navbar,
    }
  }
}
</script>

<style lang="scss" scoped>
main { background-color:#eee; min-height:calc(100vh - 56px); }
ul.nav { margin-top:10px; }
.nav-item { font-size:0.9em; }
.nav-item i { margin-right:3px; vertical-align: text-bottom; }
a.nav-link { margin:3px; padding:0;  }
a.nav-link:hover { text-decoration:none;}
 

.active { font-weight:500; }

</style>


/*
<li class="nav-item">
  <div class="text-muted small">Business Reg: 53333705D</div>
</li>
*/