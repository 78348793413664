<template>
      <!-- BLANK LAYOUT -->
      
      <Navbar />
      <main>
            <router-view/>
      </main>


</template>

<script>
import Navbar from '@/views/_layout/Navbar.vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';

export default {  
  setup() {
    const store = useStore()

    onMounted(() => {
        //console.log("mounted...");
    })

    return {
      Navbar
    }
  }
}
</script>

<style lang="scss" scoped>

main { background-color:#eee; min-height:calc(100vh - 56px); }

</style>
