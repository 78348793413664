

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './stores';
import PrimeVue from 'primevue/config';
import { createGtm } from '@gtm-support/vue-gtm';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import '@/styles/common.scss'

//import WaveUI from 'wave-ui'
//import 'wave-ui/dist/wave-ui.css'

import Equal from 'equal-vue'
import 'equal-vue/dist/style.css'

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';   


const tag = createGtm({
  id: 'GTM-584XR3M',
  defer: false,
  compatibility: false,
  //nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true,
  debug: true,
  vueRouter: router,
  //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  //trackOnNextTick: false,
});

const app = createApp(App)
.use(store)
.use(router)
.use(tag)
.use(Equal)
.use(PrimeVue)
.mount('#app');










