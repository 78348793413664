<template>
  <footer class="footer">
    <div class="content">
        FOOTA
    </div>
  </footer>
</template>

<script>


export default {
  setup() {
    

    return {

    }
  }
  
}
</script>

<style lang="scss">
.footer { height: 72px; background-color: bisque; }


</style>