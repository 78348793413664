import axios from "axios";
import env from "@/helpers/Environment";
import Utils from "@/helpers/Utils";
import { showMessage } from '@/components/Notification';
import store from '@/stores';
import { nextTick } from "vue";

const STATUS_OK = 200;
const STATUS_BAD_REQUEST = 400;
const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;
const STATUS_UNPROCESSABLE_ENTITY = 422;
const MISSING_API_CREDENTIALS = "missing_api_credentials";
const INVALID_API_CREDENTIALS = "invalid_api_credentials";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  async res => {
    const url = res.config.url;
    const path = url.substring(env.API_URL.length, url.length);
    console.log("response: ", path, res.data);

    return res;
  },
  async err => {
    console.log('intercept error', err);
    const res = err.response;

    //console.log('res??????', res);

    if (res) {
      const url = res.config.url;
      const path = url.substring(env.API_URL.length, url.length);
      //console.log("path", path)
      if (res.status == STATUS_FORBIDDEN) {
        //console.log("STATUS_FORBIDDEN", res);
        showMessage('Forbidden request: [' + path + ']', 'warning');
        //return Promise.reject(res); 
        //TODO: do global alert & log out
        //return Promise.reject("FORBIDDEN");
      } else if (res.status == STATUS_UNAUTHORIZED) {
        const publicPaths = ["refresh-token", "is-valid-setup-company", "is-valid-update-company"];
        const found = publicPaths.find(v => path.includes(v));
        if (found) {
          return Promise.reject(res); 
        }
        const r = await store.dispatch("account/refreshToken");
        if (r) { 
          const jwt = store.getters["account/token"];          
          const originalRequest = err.config;
          originalRequest.headers.Authorization = jwt; //update expired jwt
          console.log("...auto try with refresh token...");
          return axios(originalRequest);  
        } 
      } else if (res.status == STATUS_UNPROCESSABLE_ENTITY) {
        
      }
      return Promise.reject(res);
    }
    //console.log("CORS OR NETWORK ERROR");
    //TODO: do global alert for unexpected error
    return Promise.reject("CORS OR NETWORK ERROR");
  }
);


const callApi = async (method, endpoint, data) => {
  const url = env.API_URL + "v1/" + endpoint;
  let jsonData = JSON.stringify(data);

  const jwt = store.getters["account/token"];
  const headers =  { 
    'Content-Type': 'application/json',
    'Authorization': jwt,
  }
  return callApiUrl(method, url, jsonData, headers)
}

const callApiUrl = async (method, url, data, headers = {}, withCredentials = true) => {

  const config = {
    method: method,
    url: url,
    headers: headers,
    data: data,
    withCredentials: withCredentials,
  };

  //console.log("callApi config", config);

  return axios(config);
};
//get error message
// transfer logic to use/userError
//[{field: "email", message: " "}, {field: "password", message: "Incorrect credentials."}]


export { callApi, callApiUrl };
