export default {
  //API_URL: "http://192.168.1.17:3000/",
  //API_URL: "https://node-test.localhost:3000/",
  //API_URL: "http://127.0.0.1:3000/",
  //API_URL: "https://node-test.localhost:3000/",
  //API_URL: "https://8mweg22itg.execute-api.ap-southeast-1.amazonaws.com/dev/",

  //
  //VUE_APP_API_URL: "http://localhost:8080",
  //VUE_APP_FACEBOOK_APP_ID: "392059468709755",
};
