import { Message, Notification, Loadingbar } from 'equal-vue'

 
//Notification.success({title: 'Delete Product', text: product.name + " deleted successfully"});
const showMessage = (txt = "hello", type = "success") => {
  Message({text:txt, type:type});
}  

const showLoading = (show = true) => {
  if (show) {
    Loadingbar.start();
  } else {
    Loadingbar.finish();
  }
}

export  {
  showMessage,
  showLoading
}
