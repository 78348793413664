import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import { Message, Notification, Loadingbar } from 'equal-vue'
import store from './stores';

const routes = [
  {
    path: '/',
    redirect: 'showcase',
  }, 
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('./views/Home.vue'),
  //   meta: { layout:"main", requireAdmin: false, requireAuth: false } 
  // }, 
  {
    path: '/showcase',
    name: 'showcase',
    component: () => import('./views/Showcase.vue'),
    meta: { layout:"main" }
  },{
    path: '/showcase/:project',
    name: 'showcaseproject',
    component: () => import('./views/ShowcaseProject.vue'),
    props: true,
    meta: { layout:"main" }
  }, {
    path: '/monkey-society',
    name: 'monkeysociety',
    component: () => import('./views/MonkeySociety.vue'),
    meta: { layout:"blank" }
  }, 
  // {
  //   path: '/:pathMatch(.*)*', 
  //   name: 'not-found', 
  //   component: () => import('./views/NotFound.vue'),
  //   //meta: { layout:"main" },
 
  // }, { 
  //   path: '/:pathMatch(.*)', 
  //   name: 'bad-not-found', 
  //   component: () => import('./views/NotFound.vue'),
  //   //meta: { layout:"main" },
  // },
    { 
    path: '/:catchAll(.*)', 
    name: 'bad-not-found', 
    component: () => import('./views/NotFound.vue'),
    //meta: { layout:"main" },
  }
];



const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});


router.beforeEach( async(to, from, next) => {  
  next();
})



router.afterEach( async(to, from) => {
  //document.title = to.meta.title || 'Some Default Title';
  console.log(to);
  if (to.meta.layout) {
    store.dispatch("site/setLayout", to.meta.layout);
  }
});



export default router;
