<template>

<nav class="navbar shadow-sm" role="navigation" aria-label="main navigation">
    <div class="navbar-brand col-md-3 col-lg-2 me-0 px-3">
      <router-link to="/showcase">
        <img class="logo" :src="`./img/logo.png`"/>
      </router-link>
    </div>

    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
  
      <!-- <a href="#" class="">
          <i class="bi bi-three-dots-vertical"></i>
      </a> --> 



      </li>
    </ul>
</nav>

</template>

<script>

import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const showMenu = () => { console.log("test"); }


    return {
      showMenu,
    }
  }
  
}

</script>


<style lang="scss">
  .logo { 
    width:80px;
   }
  .burger-menu i { font-size:1.5em;}

</style>