import { render } from "./Main.vue?vue&type=template&id=912e15f4&scoped=true"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"

import "./Main.vue?vue&type=style&index=0&id=912e15f4&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-912e15f4"
/* custom blocks */
import block0 from "./Main.vue?vue&type=custom&index=0&blockType=li&class=nav-item"
if (typeof block0 === 'function') block0(script)


export default script