<template>
    <teleport to="head title">{{ title }}</teleport>
    <teleport to="head">
        <meta :name="metaItem.name" :content="metaItem.content" v-for="(metaItem, i) in metadata" :key="i">
    </teleport>

    <div class="wrapper">

      
      <SiteLayout/>

    </div>

</template>

<script>
import { onBeforeMount, shallowRef, toRaw, markRaw, computed, watch } from 'vue';
import { useStore } from 'vuex';
import Constant from '@/helpers/Constant';
import Navbar from '@/views/_layout/Navbar.vue';
import Footer from '@/views/_layout/Footer.vue';

//https://www.reddit.com/r/vuejs/comments/jou945/set_head_metadata_from_within_components_using/

export default {
  setup() {
    const store = useStore()
    const title = computed(() => toRaw(store.state.site.title));
    const metadata = computed(() => toRaw(store.state.site.metadata));
    const SiteLayout = computed(() => toRaw(store.state.site.layoutView));
    
    onBeforeMount( async() => {
      //loynote: set title to empty so teleport will not append to it.
      document.title = '';
    })

    return {
      Navbar,
      Footer,
      SiteLayout,
      title,
      metadata,      
    }
  }
}
</script>

<style lang="scss">
.wrapper { width:100%; min-height:100vh;}

</style>

