import { accountService } from '@/services';
import router from '@/router';
import Utils from '@/helpers/Utils';

import MainLayout from '@/views/_layout/Main.vue';
//import MainLayout2 from '@/views/_layout/Main2.vue';
import BlankLayout from '@/views/_layout/Blank.vue';

const obj = { init:false, title:"LYHCO", metadata:[], layout: "blank", layoutView: BlankLayout };
//var layoutView = MainLayout; 

export const site = {
    namespaced: true,
    state: obj,
    getters: {
      isInit: (state) => state.init,
      getLayout: (state) => {
        //console.log("wtf", state);
        return state.layoutView;
      },
    },
    actions: {
        init({ dispatch, commit }) {
          commit('siteInit');
        },
        setTitle({ dispatch, commit, state }, title) {
          console.log("settitle", title);
          commit('updateTitle', title)
        },
        setMetadata({ dispatch, commit, state }, metadata) {
          commit('updateMetadata', metadata)
        },
        setLayout({ dispatch, commit, state }, layout) {
          let v = MainLayout;
          if (!layout || layout == "default") {
            layout = "default";
            v = MainLayout;
          } else if (layout == "blank") {
            v = BlankLayout;
          } 
          // else if (layout == "main2") {
          //   v = MainLayout2;
          // } 
          commit('updateLayout', {layout, v});
        },
    },
    mutations: {
        siteInit(state) {
          state.init = true;
        },
        updateTitle(state, title) {
          state.title = title;
        },
        updateMetadata(state, metadata) {
          state.metadata = metadata;
        },
        updateLayout(state, {layout, v}) {
          state.layout = layout;
          state.layoutView = v;
          //console.log("site module mutations", state)
        },
    }
}
